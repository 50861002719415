@font-face {
   font-family: 'MyriadPro';
   font-weight: 600;
   font-style: normal;
   font-display: swap;
   /* Read next point */
   src: local('MyriadPro'), url('https://watson.cib.education/fonts/MyriadPro-Semibold.woff2') format('woff2'), url('https://watson.cib.education/fonts/MyriadPro-Semibold.woff') format('woff');
}

@font-face {
   font-family: 'MyriadPro';
   font-weight: 400;
   font-style: normal;
   font-display: swap;
   /* Read next point */
   src: local('MyriadPro'), url('https://watson.cib.education/fonts/MyriadPro-Regular.woff2') format('woff2'), url('https://watson.cib.education/fonts/MyriadPro-Regular.woff') format('woff');
}

@font-face {
   font-family: 'MyriadPro';
   font-weight: 900;
   font-style: normal;
   font-display: swap;
   /* Read next point */
   src: local('MyriadPro'), url('https://watson.cib.education/fonts/MyriadPro-Bold.woff2') format('woff2'), url('https://watson.cib.education/fonts/MyriadPro-Bold.woff') format('woff');
}

html {
   font-family: 'MyriadPro', sans-serif;
   font-weight: 400;
}

.titleText {
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   color: white;
   font-size: 1.5em;
   letter-spacing: 5px;
   margin-bottom: 0;
}

.header__container .titleText {
   min-height: 100%;
   line-height: 100%;
   padding-top: 1vh;
}

.fa-microphone {
   color: #14A76C;
}

.fa-microphone:hover {
   color: #0c6e47;
}

.fa-file-audio {
   color: #FF652F;
}

.fa-file-audio:hover {
   color: #d44916;
}

.output-display {
   background-color: black;
   height: 700px;
}

.fas,
.fa {
   cursor: pointer;
}

.background {
   background-color: black;
   color: #747474;
}

.messageListContainer {
   display: flex;
   flex-direction: column-reverse;
   align-items: flex-start;
   list-style: none;
   margin-left: 0;
   padding-left: 0;
   max-height: 50vh;
   height: 50vh;
   min-width: 90vw;
   overflow-y: scroll;
   scrollbar-width: none;

}

.messageListContainer::-webkit-scrollbar {
   display: none;
}

.messageListContainer--bottom {
   flex-direction: column;
   margin: 0;
}

.toTranslateContainer {
   height: 95%;
   position: fixed;
   width: calc(100vw - 30px);
}

.messageItem {
   display: flex;
   margin-bottom: 30px;
   border: 2px solid #dedede;
   background-color: #f1f1f1;
   color: black;
   border-radius: 5px;
   height: 46vh;
   padding: 10px;
   margin: 0.25vh 0;
   justify-content: space-between;
   font-family: 'MyriadPro', sans-serif;
   font-weight: 400;
}

.previous__messageItem {
   display: flex;
   margin-bottom: 30px;
   border: 2px solid #dedede;
   background-color: black;
   color: white;
   border-radius: 5px;
   height: auto;
   padding: 10px;
   margin: 10px 0;
   justify-content: space-between;
   font-family: 'MyriadPro', sans-serif;
   font-weight: 400;
}

.displayTranslation__container--cib .previous__messageItem {
   margin: 5px 0;
}

.messageItemText {
   justify-self: flex-start;
   font-family: 'MyriadPro', sans-serif;
   font-weight: 400;
   font-size: 5.5rem;
}

.previous__messageItemText {
   justify-self: flex-start;
   font-family: 'MyriadPro', sans-serif;
   font-weight: 400;
   font-size: 5.5rem;
}

.messageItemTime {
   font-size: 10px;
   justify-self: flex-end;
   align-self: flex-end;
}

.fadeIn {
   opacity: 1;
   width: 100px;
   height: 100px;
   transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.start__button {
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   color: white;
   cursor: pointer;
   text-align: center;
   color: #fff;
   background-color: #fe3f00;
   font-style: normal;
   font-size: 1.1rem;
   width: 100%;
   padding: 30px;
   border-radius: 5px;
   border: none;
   margin-top: 30px;
}

.start__button-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 20vw;
}

.container {
   height: 100vh;
   max-height: 100vh;
   max-width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;

}

.containerCenter {
   align-items: center;
}

.main-container {
   min-width: 100%;
}

.header__logo {
   height: 6vh;
   align-self: center;
}

.stop__button {
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   cursor: pointer;
   text-align: center;
   color: #fff;
   background-color: #fe3f00;
   font-style: normal;
   font-size: 0.8rem;
   width: 100%;
   height: auto;
   padding: 0px 0.5vw;
   border-radius: 5px;
   border: none;
   line-height: 3vh;
   height: 3vh;
}

.resolution__button {
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   cursor: pointer;
   text-align: center;
   color: #fff;
   background-color: #fe3f00;
   font-style: normal;
   font-size: 1.5rem;
   width: 100%;
   border-radius: 5px;
   border: none;
   line-height: 0.5vh;
   height: 3vh;
   min-width: 4vw;

}


.refresh__button {
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   cursor: pointer;
   text-align: center;
   color: #fff;
   background-color: #fe3f00;
   font-style: normal;
   font-size: 0.8rem;
   width: 100%;
   padding: 0px 0.5vw;
   border-radius: 5px;
   border: none;
   line-height: 3vh;
   height: 3vh;

}

.header-buttons__container {
   align-self: center;
}

.header-buttons {
   display: flex;
   gap: 0.5vw;
}

.displayTranslation__container {
   height: 96.5vh;
}

.header__container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   height: 3.5vh;
   align-items: center;
}

.home {
   display: flex;
   width: 100vw;
   height: 100vh;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 2vw;
}

.home-forbidden .titleText {
   height: auto;
}

.titleText--big {
   font-size: 4em;
}

.titleText--center {
   text-align: center;
}

.titleText--center span {
   text-align: center;
   font-size: 0.7em;
}

#linkhome {
   position: fixed;
   top: 0;
   left: 0;
   padding: 1vh;
   color: white;
   text-decoration: none;
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   text-transform: uppercase;
   font-size: 2rem;
}

.class-menu__list {
   list-style: none;
   width: 96vw;
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   padding: 0px;
   gap: 3vh;
   margin: 0px;
   max-width: 126vh;
   height: 81vh;
}

.class-menu__list li {
   width: 40vh;
   height: 25vh;
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   color: white;
   cursor: pointer;
   text-align: center;
   color: #fff;
   background-color: #fe3f00;
   font-style: normal;
   font-size: 0.8rem;
   border-radius: 5px;
   line-height: 25vh;
}

.class-menu__list li a {
   font-family: 'MyriadPro', sans-serif;
   font-weight: 900;
   cursor: pointer !important;
   text-align: center;
   color: #fff;
   background-color: #fe3f00;
   font-style: normal;
   font-size: 22vh;
   border-radius: 5px;
   line-height: 20vh;
   display: block;
   padding-top: 5vh;
   transition: 0.5s ease;
   -webkit-transition: 0.5s ease;
   -moz-transition: 0.5s ease;
   -o-transition: 0.5s ease;
   -ms-transition: 0.5s ease;
}


.class-menu__list li a sup {
   top: -0.9em;
   font-size: 0.5em;
}

.class-menu__list li a:hover {
   color: #fff;
   background-color: #7d2a0e;
   text-decoration: none;
}

.displayTranslation__container .messageItem {
   height: 21vh;
   max-height: 21vh;
}

.displayTranslation__container .messageListContainer {
   height: 74vh;
   max-height: 74vh;
}

.displayTranslation__container--cib .messageItem {
   height: 17vh;
   max-height: 17vh;
   padding: 5px 0px;
}

.displayTranslation__container--cib .messageListContainer {
   height: 79vh;
   max-height: 79vh;
}

.displayTranslation__container .messageItemText {
   font-size: 3.3rem;
   line-height: 4rem;
}

.displayTranslation__container .previous__messageItemText {
   font-size: 4rem;
   line-height: 4.8rem;
}

.displayTranslation__container.displayTranslation__container--big .messageItemText {
   font-size: 5.2rem;
   line-height: 5.8rem;
}

.displayTranslation__container.displayTranslation__container--big .previous__messageItemText {
   font-size: 6.5rem;
   line-height: 7rem;
}

.displayTranslation__container.displayTranslation__container--cib .messageItemText {
   font-size: 2.45rem;
   line-height: 2.4rem;
}

.displayTranslation__container.displayTranslation__container--cib .previous__messageItemText {
   font-size: 3.4rem;
   line-height: 3.6rem;
}

@media (max-width: 1100px) {
   .displayTranslation__container.displayTranslation__container--cib .messageItemText {
      font-size: 2.8rem;
      line-height: 2.65rem;
   }
}